import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { HomePageModule } from './pages/home/home.module';
import { AccountPageModule } from './pages/search-detail/account/account.module';
import { TopicPageModule } from './pages/search-detail/topic/topic.module';
import { TransactionPageModule } from './pages/search-detail/transaction/transaction.module';
import { SearchResultsPageModule } from './pages/search-results/search-results.module';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderModule } from './shared/header/header.module';
@NgModule({
    declarations: [AppComponent],
    imports: [
        HomePageModule,
        SearchResultsPageModule,
        TransactionPageModule,
        AccountPageModule,
        TopicPageModule,
        HttpClientModule,
        HeaderModule,
        FooterComponent,
        BrowserModule,
        RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
        FontAwesomeModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
