import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ChainRecord = {
    transaction_id?: string;
    account?: string;
};
export interface SearchStage {
    stages: ChainRecord[];
    type: string;
}
export interface SearchResult {
    [key: string]: SearchStage[] | undefined;
}
export interface SearchResponseBody {
    body: SearchResult;
}

@Injectable({
    providedIn: 'root',
})
export class SearchResultsService {
    private searchIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private searchResultsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public searchId$ = this.searchIdSubject.asObservable();
    public searchResults$ = this.searchResultsSubject.asObservable();

    setSearchResults(results: SearchResponseBody, id:string): void {
        let transformedValues = this.transformResultsToArray(results.body);
        this.searchResultsSubject.next(transformedValues);
        this.searchIdSubject.next(id);
    }

    transformResultsToArray(results: SearchResult): SearchStage[] {
        const mapped = Object.keys(results).map((key) => ({ type: key, stages: (results as any)[key] }));
        return mapped;
    }
}
