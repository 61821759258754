import { DatePipe } from '@angular/common';

export type TxType = 'transactions' | 'topics' | 'accounts';
export type NetType = 'main' | 'test' | 'prev';
export type NetTypeLong = 'mainnet' | 'testnet' | 'prevnet';

export function netName(net: NetType): string {
    const typeMapping: Record<NetType, string> = {
        main: 'Mainnet',
        test: 'Testnet',
        prev: 'Prevnet',
    };
    return typeMapping[net as keyof typeof typeMapping] || net;
}

export function netNameExtended(net: NetType): string {
    const typeMapping: Record<NetTypeLong, string> = {
        mainnet: 'Mainnet',
        testnet: 'Testnet',
        prevnet: 'Prevnet',
    };
    return typeMapping[net as keyof typeof typeMapping] || net;
}

export function mapType(type: TxType): string {
    const typeMapping: Record<TxType, string> = {
        transactions: 'Transaction',
        topics: 'Topic',
        accounts: 'Account',
    };
    return typeMapping[type as keyof typeof typeMapping] || type;
}

export function convertTinybarsToHbars(tinybars: number) {
    return tinybars / 100_000_000;
}

export function formatTimestamp(timestamp: string): string {
    const datePipe = new DatePipe('en-US'); // Assuming English locale
    const timestampNumber = parseFloat(timestamp);
    const date = new Date(timestampNumber * 1000);
    const offset = 10 * 60;
    const localTime = new Date(date.getTime() + offset * 60 * 1000);
    const formatted = datePipe.transform(localTime, 'dd MMM yyyy, HH:mm:ss');
    return `${formatted} GMT+10`;
}

export function base64ToHex(base64: string | undefined): string {
    if(!base64) return '';
    const raw = atob(base64);
    let hex = '';
    for (let i = 0; i < raw.length; i++) {
        const hexChar = raw.charCodeAt(i).toString(16);
        hex += hexChar.length === 2 ? hexChar : '0' + hexChar;
    }
    return hex;
}
