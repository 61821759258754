import { Component, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Transaction, TransactionTransfer } from '@fsco/ui/types-explorer';
import { map, tap } from 'rxjs';
import { ApiService } from '../../../services';
import { base64ToHex, convertTinybarsToHbars, formatTimestamp, netNameExtended, NetType } from '../../../services/utility.service';

@Component({
    selector: 'app-transaction-detail',
    templateUrl: './transaction.component.html',
    styleUrls: ['./transaction.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TransactionComponent {
    id = '';
    network = '';
    transactionJson: Transaction[] | null = null;
    transactionList: Transaction | null = null;
    activeTab = 'overview';
    networkType = '';
    payerAccount: string | null = '';
    transferFrom: TransactionTransfer[] = [{ account: 's' }, { account: '2' }];
    transferTo: TransactionTransfer[] = [];

    faResultSuccess = faCheckCircle;

    constructor(private titleService: Title, private route: ActivatedRoute, private apiService: ApiService) {}

    attributeIsEmpty(attributeName: string): boolean {
        return !this.transactionList || !this.transactionList[attributeName];
    }

    getPayerAccount(): void {
        this.payerAccount = this.transactionList?.transfers?.[this.transactionList?.transfers.length - 1]?.account ?? null;
    }

    getTransactionTransfers(transfers: TransactionTransfer[]): {
        from: TransactionTransfer[];
        to: TransactionTransfer[];
    } {
        const to = transfers.filter((transfer: TransactionTransfer) => transfer.amount !== undefined && transfer.amount > 0).map((transfer: TransactionTransfer) => transfer);
        const from = transfers.filter((transfer: TransactionTransfer) => transfer.amount !== undefined && transfer.amount < 0).map((transfer: TransactionTransfer) => transfer);
        return { from, to };
    }

    getTransactionDetail(): void {
        const idParam = this.route.snapshot.paramMap.get('id');
        const netParam = this.route.snapshot.paramMap.get('network');
        this.id = idParam !== null ? idParam : '';
        this.network = netParam !== null ? netParam : '';
        if (!this.id || !this.network) return void 0;

        this.apiService
            .getTransaction({
                id: this.id,
                network: this.network,
            })
            .pipe(
                map((response: any) => {
                    this.transactionJson = response.body.transactions;
                    return response.body.transactions[0];
                }),
                tap((transaction: Transaction) => {
                    this.transactionList = transaction;
                    this.networkType = netNameExtended(this.network as NetType);
                    this.getPayerAccount();
                }),
                map((transaction: Transaction) => this.getTransactionTransfers(transaction?.['transfers'] || [])),
            )
            .subscribe(({ from, to }) => {
                this.transferFrom = from;
                this.transferTo = to;
            });
    }

    convertAmount(tinybars: number): number {
        return convertTinybarsToHbars(tinybars);
    }

    convertTimeConsensus(timestamp: string | undefined): string {
        return this.transactionList?.consensus_timestamp ? formatTimestamp(this.transactionList?.consensus_timestamp) : 'N/A';
    }

    base64ToHex(text: string | undefined) {
        return base64ToHex(text);
    }

    ngOnInit(): void {
        this.titleService.setTitle('Transaction Detail ');
        this.getTransactionDetail();
    }
}
