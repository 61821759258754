import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private themeSubject = new BehaviorSubject<string>('dark');
    theme$ = this.themeSubject.asObservable();

    constructor() {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
            this.themeSubject.next(storedTheme);
        }
    }

    toggleTheme() {
        if (this.themeSubject.value === 'light') {
            this.themeSubject.next('dark');
            localStorage.setItem('theme', 'dark');
        } else {
            this.themeSubject.next('light');
            localStorage.setItem('theme', 'light');
        }
    }
}
