import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TopicComponent } from './topic.component';

@NgModule({
    declarations: [TopicComponent],
    imports: [CommonModule, FontAwesomeModule],
    exports: [TopicComponent],
})
export class TopicPageModule {}
