<div class="container d-flex flex-column">
    <div class="row justify-content-center align-items-center m-0 pt-5">
        <div class="col-6 text-center pt-3">
            <h1 class="text-center mb-4">Search Results</h1>
            <app-search-box></app-search-box>
        </div>
    </div>
    <div class="search-container-result mt-5">
        <div class="row d-flex flex-column justify-content-center align-items-center mb-5" *ngIf="searchResults.length > 0">
            <div *ngFor="let result of searchResults" class="col col-6 nettype-{{ result.stage }}" >
                <a href="/{{ result.stageName | lowercase }}/{{ result.type | lowercase }}/{{ result.id }}" class="text-decoration-none text-dark">
                    <div class="card mb-4 items-results rounded">
                        <div class="card-body pb-2 pt-4 ps-4 pe-4">
                            <span class="badge badge-pill outline net-{{ result.stage }}">{{ result.stageName }}</span>
                            <h5 class="card-title mt-2 mb-3">{{ result.typeName }} <span class="recordId">#{{ getId(result) }}</span></h5>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div class="row d-flex flex-column justify-content-center align-items-center mb-5" *ngIf="searchResults.length === 0">
            <div class="col col-6">
                <div class="card mb-4 total-results rounded-pill align-items-center text-center">
                    <div class="card-body">
                        <span>{{ searchResults.length }}</span> search results
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
