import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services';

@Component({
    selector: 'app-topic-detail',
    templateUrl: './topic.component.html',
    styleUrls: ['./topic.component.scss'],
})
export class TopicComponent {
    network = '';
    networkType = '';
    constructor(private titleService: Title, private route: ActivatedRoute, private apiService: ApiService) {}

    ngOnInit(): void {
        this.titleService.setTitle('Topic Detail ');
    }
}
