import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { TransactionComponent } from './transaction.component';

@NgModule({
    declarations: [TransactionComponent],
    imports: [CommonModule, FontAwesomeModule, NgxJsonViewerModule],
    exports: [TransactionComponent],
})
export class TransactionPageModule {}
