<footer class="bottom-nav align-items-baseline">
    <div class="container pb-5 justify-content-center">
        <div class="row">
            <div class="bottom-nav-logo col-sm-12 col-md-7 col-lg-6 col-xl-6 mb-3 mb-md-0">
                <div class="row d-flex justify-content-md-start justify-content-center">
                    <div class="bottom-nav-logo-image"></div>
                    <div class="bottom-nav-logo-text">Network is a ledger explorer for the Hedera network. Release v{{ version }} built {{ currentDate }}</div>
                </div>
            </div>
            <div class="bottom-nav-links justify-content-md-end col-sm-12 col-md-5 col-lg-6 col-xl-6 justify-content-center">
                <a href="#">About</a>
                <a href="#">Contact</a>
                <a href="#">Privacy Policy</a>
            </div>
        </div>
    </div>
</footer>
