<div class="container d-flex flex-column mb-5 network-theme network-theme-{{ network }}">
    <div class="row justify-content-center align-items-center m-0 pt-5 mb-5">
        <div class="col-lg-10 col-sm-12 text-left pt-3 pb-5">
            <span class="badge rounded-pill badge-primary outline p-2 ps-3 pe-3">{{ networkType }}</span>
            <h1 class="text-left mb-4 mt-2">
                <strong>Transaction</strong>
                {{ accountList?.account }}
            </h1>
        </div>
        <div class="container-fluid col-lg-10 col-sm-12">
            <ul class="nav nav-tabs chain_detail text-left">
                <li class="nav-item">
                    <a class="nav-link" [class.active]="activeTab === 'overview'" (click)="activeTab = 'overview'"
                        >OVERVIEW</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" [class.active]="activeTab === 'payload'" (click)="activeTab = 'payload'"
                        >PAYLOAD</a
                    >
                </li>
            </ul>
            <div class="content-tabs pt-5">
                <div [hidden]="activeTab !== 'overview'">
                    <div class="row d-flex align-items-stretch">
                        <div class="col col-6 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('result')"
                            >
                                <h4>Balance</h4>
                                <div>
                                    <fa-icon class="green" [icon]="faResultSuccess"></fa-icon>
                                    {{ accountList.balance.balance }}
                                </div>
                            </div>
                        </div>
                        <div class="col col-6 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('name')"
                            >
                                <h4>Alias</h4>
                                {{ accountList?.alias }}
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-stretch">
                        <div class="col col-12 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('staked_node_id')"
                            >
                                <h4>STAKED NODE ID</h4>
                                <div class="inner-text">{{ base64ToHex(accountList?.staked_node_id) }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-stretch">
                        <div class="col col-12 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('pending_reward')"
                            >
                                <h4>PENDING STAKING REWARDS</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-stretch">
                        <div class="col col-12 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('memo')"
                            >
                                <h4>MEMO</h4>
                                <div class="inner-text">{{ accountList?.memo || 'None' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-stretch">
                        <div class="col col-12 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('evm_address')"
                            >
                                <h4>EVM ADDRESS</h4>
                                <div class="inner-text">{{ accountList?.evm_address || 'None' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="activeTab !== 'payload'">
                    <div class="payload-wrap">
                        <ngx-json-viewer [json]="accountJson"></ngx-json-viewer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
