import { Route } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AccountComponent, TopicComponent, TransactionComponent } from './pages/search-detail';
import { SearchResultsComponent } from './pages/search-results/search-results.component';

export const appRoutes: Route[] = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'search',
        component: SearchResultsComponent,
    },
    {
        path: ':network/accounts/:id',
        component: AccountComponent,
    },
    {
        path: ':network/topics/:id',
        component: TopicComponent,
    },
    {
        path: ':network/transactions/:id',
        component: TransactionComponent,
    },
];
