import { Component, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Account } from '@fsco/ui/types-explorer';
import { map, tap } from 'rxjs';
import { ApiService } from '../../../services';
import { base64ToHex, convertTinybarsToHbars, formatTimestamp, netNameExtended, NetType } from '../../../services/utility.service';

@Component({
    selector: 'app-account-detail',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AccountComponent {
    id = '';
    network = '';
    accountJson: Account[] | null = null;
    accountList: Account | null = null;
    activeTab = 'overview';
    networkType = '';
    payerAccount: string | null = '';

    faResultSuccess = faCheckCircle;

    constructor(private titleService: Title, private route: ActivatedRoute, private apiService: ApiService) {}

    attributeIsEmpty(attributeName: string): boolean {
        return !this.accountList || !this.accountList[attributeName];
    }

    getAccountDetail(): void {
        const idParam = this.route.snapshot.paramMap.get('id');
        const netParam = this.route.snapshot.paramMap.get('network');
        this.id = idParam !== null ? idParam : '';
        this.network = netParam !== null ? netParam : '';
        if (!this.id || !this.network) return void 0;

        this.apiService
            .getAccount({
                id: this.id,
                network: this.network,
            })
            .pipe(
                map((response: any) => {
                    this.accountJson = response.body;
                    return response.body;
                }),
                tap((account: Account) => {
                    this.accountList = account;
                    this.networkType = netNameExtended(this.network as NetType);
                }),
            )
            .subscribe(({ from, to }) => {});
    }

    convertAmount(tinybars: number): number {
        return convertTinybarsToHbars(tinybars);
    }

    convertTimeConsensus(timestamp: string | undefined): string {
        return this.accountList?.consensus_timestamp ? formatTimestamp(this.accountList?.consensus_timestamp) : 'N/A';
    }

    base64ToHex(text: string | undefined) {
        return base64ToHex(text);
    }

    ngOnInit(): void {
        this.titleService.setTitle('Account Detail ');
        this.getAccountDetail();
    }
}
