import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SearchBoxModule } from '../../shared/search-box/search-box.module';
import { HomeComponent } from './home.component';

@NgModule({
    declarations: [HomeComponent],
    imports: [CommonModule, SearchBoxModule],
    exports: [HomeComponent],
})
export class HomePageModule {}
