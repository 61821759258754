import { Component, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { combineLatest, filter, map, mergeMap, Observable, of, toArray } from 'rxjs';
import { ChainRecord, SearchResultsService, SearchStage } from '../../services';
import { mapType, netName, NetType, TxType } from '../../services/utility.service';

export interface CardsResults {
    id: string;
    type: string;
    typeName: string;
    stage: string;
    stageName: string;
}
@Component({
    selector: 'app-search-box-results',
    templateUrl: 'search-results.component.html',
    styleUrls: ['search-results.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SearchResultsComponent {
    searchResults: CardsResults[] = [];
    searchId = '';
    constructor(private titleService: Title, private searchResultsService: SearchResultsService) {}

    getId(record: any) {
        return record.id || record.account || '';
    }
    getIdFromChainRecord(record: ChainRecord): string {
        return record.transaction_id || record.account || this.searchId;
    }
    mapToCards(results: SearchStage[]): Observable<CardsResults[]> {
        return of(results).pipe(
            mergeMap((txTypes) => txTypes),
            filter((txType) => txType.stages != null),
            mergeMap((txType) =>
                Object.entries(txType.stages || {}).map(([stageKey, stageItems]) => ({
                    txType,
                    stageKey,
                    stageItems,
                })),
            ),
            mergeMap(({ txType, stageKey, stageItems }) => {
                if (txType.type === 'transactions') {
                    return Object.values(stageItems).map((chainRecord) => ({ txType, stageKey, chainRecord }));
                }
                return of(stageItems).pipe(map((item) => ({ txType, stageKey, chainRecord: item })));
            }),
            map(({ txType, stageKey, chainRecord }) => ({
                id: this.getIdFromChainRecord(chainRecord as ChainRecord),
                type: txType.type,
                typeName: mapType(txType.type as TxType),
                stage: stageKey,
                stageName: netName(stageKey as NetType),
            })),
            toArray(),
        );
    }

    ngOnInit(): void {
        this.titleService.setTitle('Search Results');
        combineLatest([this.searchResultsService.searchId$, this.searchResultsService.searchResults$])
            .pipe(
                mergeMap(([id, results]) => {
                    this.searchId = id;
                    return this.mapToCards(results);
                }),
            )
            .subscribe((mappedResults) => {
                this.searchResults = mappedResults;
            });
    }
}
