<div class="container d-flex flex-column mb-5 network-theme network-theme-{{ network }}">
    <div class="row justify-content-center align-items-center m-0 pt-5 mb-5">
        <div class="col-12 text-left pt-3 pb-5">
            <span class="badge badge-pill badge-primary outline">{{ networkType }}</span>
            <h1 class="text-left mb-4">
                <strong>Topic</strong>
                <!--{{ transactionList?.transaction_id }}-->
            </h1>
        </div>
        <div class="container-fluid">
            Soon...
        </div>
    </div>
</div>
