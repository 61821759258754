import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { finalize, map } from 'rxjs';
import { ApiService, SearchResultsService } from '../../services';

@Component({
    selector: 'app-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SearchBoxComponent implements OnInit {
    searchActive = '';
    iconSearch = faBars;
    searchParamValue = '';
    lastSearchedValue: string | null = null;
    @Input() compact: boolean = false;
    @ViewChild('searchInput', { static: false })
    searchInput!: ElementRef<HTMLInputElement>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiService,
        private searchResultsService: SearchResultsService,
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            const searchValue = params['search'] ? decodeURIComponent(params['search']) : '';
            if (searchValue && searchValue !== this.lastSearchedValue) {
                this.searchParamValue = searchValue;
                this.lastSearchedValue = this.searchParamValue;
                this.executeSearch(this.searchParamValue);
            }
        });
    }

    executeSearch(searchParamValue?: string) {
        const inputValue = searchParamValue ?? this.searchInput.nativeElement.value;

        if (inputValue.length > 0) {
            this.searchActive = 'searching';

            this.apiService
                .search({
                    search: inputValue,
                })
                .pipe(
                    map((response: any) => {
                        return response;
                    }),
                    finalize(() => {
                        this.searchActive = '';
                    })
                )
                .subscribe((items: any) => {
                    const cleanedInput = encodeURIComponent(inputValue);
                    this.searchResultsService.setSearchResults(items, inputValue);
                    if (inputValue !== this.lastSearchedValue) {
                        this.router.navigate(['/search'], { queryParams: { search: cleanedInput } });
                    }
                });
        }
    }

    onSearchButtonClick() {
        this.executeSearch();
    }
}
