<div class="container d-flex flex-column mb-5 network-theme network-theme-{{ network }}">
    <div class="row justify-content-center align-items-center m-0 pt-5 mb-5">
        <div class="col-lg-10 col-sm-12 text-left pt-3 pb-5">
            <span class="badge rounded-pill badge-primary outline p-2 ps-3 pe-3">{{ networkType }}</span>
            <h1 class="text-left mb-4 mt-2">
                <strong>Transaction</strong>
                {{ transactionList?.transaction_id }}
            </h1>
        </div>
        <div class="container-fluid col-lg-10 col-sm-12">
            <ul class="nav nav-tabs chain_detail text-left">
                <li class="nav-item">
                    <a class="nav-link" [class.active]="activeTab === 'overview'" (click)="activeTab = 'overview'"
                        >OVERVIEW</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" [class.active]="activeTab === 'payload'" (click)="activeTab = 'payload'"
                        >PAYLOAD</a
                    >
                </li>
            </ul>
            <div class="content-tabs pt-5">
                <div [hidden]="activeTab !== 'overview'">
                    <div class="row d-flex align-items-stretch">
                        <div class="col col-6 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('result')"
                            >
                                <h4>Result</h4>
                                <div class="transaction_state {{ transactionList?.result | lowercase }}">
                                    <fa-icon class="green" [icon]="faResultSuccess"></fa-icon>
                                    {{ transactionList?.result }}
                                </div>
                            </div>
                        </div>
                        <div class="col col-6 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('name')"
                            >
                                <h4>TYPE</h4>
                                {{ transactionList?.name }}
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-stretch">
                        <div class="col col-12 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('transaction_hash')"
                            >
                                <h4>TRANSACTION HASH</h4>
                                <div class="inner-text">{{ base64ToHex(transactionList?.transaction_hash) }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-stretch">
                        <div class="col col-12 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('transfers')"
                            >
                                <h4>HBAR TRANSFERS</h4>
                                <div class="row">
                                    <div class="col-6">
                                        <h5>From</h5>
                                        <div class="transfers transfers-out" *ngIf="transferFrom.length > 0">
                                            <div class="row pr-4" *ngFor="let transfer of transferFrom">
                                                <div class="account col-6 align-items-left main-color-sub">
                                                    Account {{ transfer.account }}
                                                </div>
                                                <div class="amount col-6 align-items-right text-right">
                                                    {{
                                                        transfer.amount !== undefined
                                                            ? convertAmount(transfer.amount)
                                                            : 'N/A'
                                                    }}
                                                    ℏ
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <h5>To</h5>
                                        <div class="transfers transfers-in" *ngIf="transferTo.length > 0">
                                            <div class="row" *ngFor="let transfer of transferTo">
                                                <div class="account col-6 align-items-left main-color-sub">
                                                    Account {{ transfer.account }}
                                                </div>
                                                <div class="amount col-6 align-items-right text-right">
                                                    +{{
                                                        transfer.amount !== undefined
                                                            ? convertAmount(transfer.amount)
                                                            : 'N/A'
                                                    }}
                                                    ℏ
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-stretch">
                        <div class="col col-6 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('consensus_timestamp')"
                            >
                                <h4>Consensus Time</h4>
                                <div class="inner-text">
                                    <span class="d-block concesus_time_formated mt-1 mb-1">
                                        {{ convertTimeConsensus(transactionList?.consensus_timestamp) }}
                                    </span>
                                    <span class="d-block concesus_time mt-1">
                                        ({{ transactionList?.consensus_timestamp }})
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col col-6 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('transfers')"
                            >
                                <h4>Payer account</h4>
                                <div class="inner-text">
                                    {{ payerAccount }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-stretch">
                        <div class="col col-12 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('memo_base64')"
                            >
                                <h4>MEMO</h4>
                                <div class="inner-text">{{ transactionList?.memo_base64 || 'None' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-stretch">
                        <div class="col col-12 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('ft_transfer')"
                            >
                                <h4>TOKEN TRANSFERS</h4>
                                <div class="inner-text">{{ transactionList?.ft_transfer || 'None' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('nft_transfer')"
                            >
                                <h4>NFT TRANSFER</h4>
                                <div class="inner-text">{{ transactionList?.nft_transfer || 'None' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-6 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('entity_id')"
                            >
                                <h4>eNTITY id</h4>
                                <div class="inner-text">{{ transactionList?.entity_id || 'None' }}</div>
                            </div>
                        </div>
                        <div class="col col-6 p-3">
                            <div
                                class="inner-content"
                                style="height: 100%; width: 100%"
                                [class.empty]="attributeIsEmpty('node')"
                            >
                                <h4>Node Account</h4>
                                <div class="inner-text">{{ transactionList?.node || 'None' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="activeTab !== 'payload'">
                    <div class="payload-wrap">
                        <ngx-json-viewer [json]="transactionJson"></ngx-json-viewer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
