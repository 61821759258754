import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SearchBoxModule } from '../search-box/search-box.module';
import { HeaderComponent } from './header.component';

@NgModule({
    declarations: [HeaderComponent],
    imports: [CommonModule, SearchBoxModule, FormsModule],
    exports: [HeaderComponent],
})
export class HeaderModule {}
