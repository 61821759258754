import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AccountComponent } from './account.component';

@NgModule({
    declarations: [AccountComponent],
    imports: [CommonModule, FontAwesomeModule, NgxJsonViewerModule],
    exports: [AccountComponent],
})
export class AccountPageModule {}
