import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'explorer';
    theme: 'dark' | 'light' = 'dark';

    ngOnInit() {
        this.initializeTheme();
    }

    initializeTheme() {
        const storedThemePreference = localStorage.getItem('user-theme-preference');

        if (storedThemePreference) {
            this.theme = storedThemePreference === 'light' ? 'light' : 'dark';
        } else {
            this.theme = window.matchMedia('(prefers-color-scheme: light)').matches ? 'light' : 'dark';
        }

        this.applyTheme();
    }

    applyTheme() {
        document.body.setAttribute('data-theme', this.theme);
    }
}
