import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ThemeService } from '../../services/theme.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
    showLogo = false;
    showSearcher = false;
    currentTheme: string = 'light';
    constructor(private router: Router, private themeService: ThemeService) {
        if (localStorage.getItem('theme')) {
            this.currentTheme = localStorage.getItem('theme')!;
            document.body.setAttribute('data-theme', this.currentTheme);
        }
    }

    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.showLogo = event.urlAfterRedirects !== '/';
                this.showSearcher = event.urlAfterRedirects !== '/' && !event.urlAfterRedirects.startsWith('/search');
            }
        });

        this.themeService.theme$.subscribe((theme) => {
            this.currentTheme = theme;
            document.body.setAttribute('data-theme', theme);
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }
}
