import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-footer',
    standalone: true,
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
    version = '1.0';
    currentDate!: string;

    ngOnInit() {
        this.updateCurrentDate();
    }

    updateCurrentDate() {
        const now = new Date();
        this.currentDate = now.toUTCString();
    }
}
