<div class="search-container {{ searchActive }} {{ compact ? 'compact' : '' }}">
    <div class="custom-input-group rounded-pill">
        <div class="spinner"></div>
        <input #searchInput type="text" class="form-control" (keyup.enter)="onSearchButtonClick()" placeholder="{{ compact ? 'Search by ID': 'Search by transaction, topic, nft' }}" [value]="searchParamValue || ''"/>
        <div class="custom-input-group-icon">
            <svg class="svg-icon search-icon" aria-labelledby="title desc" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7">
                <title id="title">Search Icon</title>
                <desc id="desc">A magnifying glass icon.</desc>
                <g class="search-path" fill="none" stroke="#848F91">
                    <path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4" />
                    <circle cx="8" cy="8" r="7" />
                </g>
            </svg>
        </div>
        <div class="input-group-append">
            <button class="btn" type="button" (click)="onSearchButtonClick()">Search</button>
        </div>
    </div>
</div>
