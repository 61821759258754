<header class="top-nav">
    <div class="container-fluid d-flex pl-4 pr-4 pt-4 pb-1 align-items-start">
        <div class="col-3" *ngIf="showLogo">
            <a href="/" target="_self">
                <div class="logo ms-4"></div>
            </a>
        </div>
        <div class="{{ showLogo ? 'col-9' : 'col-12' }}">
            <div class="d-flex justify-content-end align-items-center">
                <div *ngIf="showSearcher" class="mr-4">
                    <app-search-box [compact]="true"></app-search-box>
                </div>
                <div class="ms-3 me-3">
                    <button class="theme-icon" (click)="toggleTheme()"></button>
                </div>
                <div>
                    <div class="ml-auto menu-icon">
                        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                            <rect width="100%" height="3" rx="1.5"/>
                            <rect y="13" width="100%" height="3" rx="1.5"/>
                            <rect y="26" width="100%" height="3" rx="1.5"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
