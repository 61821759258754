import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private httpService: HttpClient) {}

    search(
        params: { search: string } = {
            search: '',
        },
    ): Observable<any> {
        return this.httpService.get(environment.networkUrl + 'explorer/search', {
            params: {
                q: params.search,
            },
        });
    }

    getTransaction(params: { id?: string; network?: string }): Observable<any> {
        return this.httpService.get(environment.networkUrl + `explorer/${params.network}/transactions/${params.id}`);
    }

    getTopic(params: { id?: string; network?: string }): Observable<any> {
        return this.httpService.get(environment.networkUrl + `explorer/${params.network}/topics/${params.id}`);
    }

    getAccount(params: { id?: string; network?: string }): Observable<any> {
        return this.httpService.get(environment.networkUrl + `explorer/${params.network}/accounts/${params.id}`);
    }

}
